import { AwsRum, AwsRumConfig } from 'aws-rum-web';

export let awsRum: AwsRum;

export const RumClientInitialize = (userAlias: string) => {
  const awsCloudwatchRumConfig = getCloudwatchRUMConfig();
  try {
    const config: AwsRumConfig = {
      sessionSampleRate: 1,
      guestRoleArn: awsCloudwatchRumConfig.guestRoleArn,
      identityPoolId: awsCloudwatchRumConfig.identityPoolId,
      endpoint: awsCloudwatchRumConfig.endpoint,
      telemetries: awsCloudwatchRumConfig.telemetries,
      allowCookies: true,
      enableXRay: true,
      enableRumClient: true,
      disableAutoPageView: false,
      pageIdFormat: 'PATH_AND_HASH'
    };

    const APPLICATION_ID: string = awsCloudwatchRumConfig.applicationId;
    const APPLICATION_VERSION: string = awsCloudwatchRumConfig.applicationVersion;
    const APPLICATION_REGION: string = awsCloudwatchRumConfig.applicationRegion;

    awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);

    awsRum.addSessionAttributes({
      userAlias: userAlias
    });
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }

  return awsRum;
};

const getCloudwatchRUMConfig = () => {
  const rumConfiguration = {
    applicationId: 'ecf9077f-39e3-4a59-ad25-23aa9c0553c6',
    applicationVersion: '1.0.0',
    applicationRegion: 'us-west-2',
    guestRoleArn: 'arn:aws:iam::186446126748:role/DaS-FinSuite-Portal-Cogni-CognitoUnauthenticatedRo-1DYF4TXD824Z',
    identityPoolId: 'us-west-2:c3557819-251d-4198-a1f6-3eb3fab8357b',
    endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
    telemetries: ['performance', 'errors', 'http']
  };
  return rumConfiguration;
};

// Example from AWS :
// To Record custom event
/*
awsRum.recordEvent('my_custom_event', {
        location: 'IAD', 
        current_url: 'amazonaws.com', 
        user_interaction: {
            interaction_1 : "click",
            interaction_2 : "scroll"
        }, 
        visit_count:10
    }
)
*/
export const recordInAppEvent = (eventName: string, eventObject: any) => {
  awsRum.recordEvent(eventName, {
    ...eventObject
  });
};

export function recordApiRequest(queryName: string, responseTime: number) {
  awsRum.recordEvent('apiRequest', {
    queryName,
    responseTime
  });
}
