import { AppLayout, Flashbar, FlashbarProps, Link, MixedLineBarChartProps, SelectProps, Tabs } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { DFPHomePageSideNavigation } from 'src/components/dfp-home-page/DFPHomePageSideNavigation';
import { DFPBreadcrumbs } from 'src/components/generic-components/DFPBreadcrumb';
import { FinTechOpsHref } from '../FinTechOpsConstants';
import { TicketContext } from './TicketsContext';
import { TicketDetailsPage } from './TicketsDetailsTab';
import {
  AreaDataSeries,
  BarResDataSeries,
  LineAppDataSeries,
  OpenTicketsTableData,
  PieChartDataSeries,
  StatusType,
  TicketAggregationTableData,
  TicketsParsedDataItem,
  TotalTicketsOpenComponent
} from './TicketsInterfaces';
import { OverViewContent } from './TicketsOverview';
import { TicketsSelectionFilters } from './TicketsSelectionFilters';

export const TicketsPageMain: React.FC = () => {
  //For Overview Page
  const [flashbarItems, setFlashbarItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const [statusOfGraphTab, setStatusOfGraphTab] = useState<StatusType>('loading');

  //Graphs Data
  const [statusPieData, setStatusPieData] = useState<PieChartDataSeries[]>([]);
  const [ageTicketData, setAgeTicketData] = useState<MixedLineBarChartProps.BarDataSeries<string | number | Date>[]>([]);
  const [sevTicketsByDateData, setSevTicketsByDateData] = useState<BarResDataSeries[]>([]);
  const [ticketsByApplication, setTicketsByApplication] = useState<LineAppDataSeries[]>([]);
  const [ticketsByDateStatus, setTicketsByDateStatus] = useState<BarResDataSeries[]>([]);
  const [ticketsByResolution, setTicketsByResolution] = useState<BarResDataSeries[]>([]);
  const [ticketsByTimeSpent, setTicketsByTimeSpent] = useState<AreaDataSeries[]>([]);
  const [ticketsByTeam, setTicketsByTeam] = useState<BarResDataSeries[]>([]);

  //Domain for Graphs
  const [sevTicketsByDateDomain, setSevTicketsByDateDomain] = useState<string[]>([]);
  const [ticketsByApplicationYDomain, setTicketsByApplicationYDomain] = useState<number[]>([]);
  const [ticketsByApplicationXDomain, setTicketsByApplicationXDomain] = useState<string[]>([]);
  const [ticketsByDatesStatusDomain, setTicketsByDatesStatusDomain] = useState<string[]>([]);
  const [ticketsByResolutionDomain, setTicketsByResolutionDomain] = useState<string[]>([]);
  const [ticketsByTimeSpentDomain, setTicketsByTimeSpentDomain] = useState<string[]>([]);
  const [ticketsByStatusYDomain, setTicketsByStatusYDomain] = useState<number[]>([]);
  const [ageTicketYDomain, setAgeTicketYDomain] = useState<number[]>([]);
  const [ageTicketXDomain, setAgeTicketXDomain] = useState<string[]>([]);
  const [ticketsByTeamYDomain, setTicketsByTeamYDomain] = useState<number[]>([]);
  const [ticketsByTeamXDomain, setTicketsByTeamXDomain] = useState<string[]>([]);

  //For Tables/Detail Page
  const [statusOfDetailsTab, setStatusOfDetailsTab] = useState<StatusType>('loading');
  const [aggregatedDataTableApplications, setAggregatedDataTableApplications] = useState<TicketAggregationTableData>(
    {} as TicketAggregationTableData
  );
  const [aggregatedDataTableTeams, setAggregatedDataTableTeams] = useState<TicketAggregationTableData>({} as TicketAggregationTableData);
  const [ticketDetailsTable, setTicketDetailsTable] = useState<OpenTicketsTableData>({} as OpenTicketsTableData);
  const [openTickets, setOpenTickets] = useState<number>(0);
  const [openTicketContainer, setOpenTicketContainer] = useState<TotalTicketsOpenComponent[]>([]);
  const [openTicketsByTeam, setOpenTicketsByTeam] = useState<Record<string, number>>({});
  const [resolvedMonthsList, setResolvedMonthsList] = useState<string[]>([]);
  const [filteredResolvedMonthList, setFilteredResolvedMonthsList] = useState<string[]>([]);

  const [resolvedResolutionTypeList, setResolvedResolutionTypeList] = useState<string[]>([]);
  const [filteredResolvedResolutionTypeList, setFilteredResolvedResolutionTypeList] = useState<string[]>([]);

  const [ticketResolvedDetailsTable, setTicketResolvedDetailsTable] = useState<OpenTicketsTableData>({} as OpenTicketsTableData);

  const [filteredResolvedDetailsTable, setFilteredResolvedDetailsTable] = useState<TicketsParsedDataItem[]>([]);

  const displayNotification = (status: FlashbarProps.Type, message: string | React.ReactNode, linkMessage?: string) => {
    function addTicketLink() {
      if (linkMessage) {
        return (
          <Link variant="primary" target="_blank" href={linkMessage} color="inverted" fontSize="body-m">
            submit a ticket
          </Link>
        );
      }
    }
    setFlashbarItems([
      {
        type: status,
        content: (
          <>
            {message} 'or ' {addTicketLink()}
          </>
        ),
        dismissible: true,
        dismissLabel: 'Dismiss message',
        onDismiss: () => setFlashbarItems([]),
        id: 'message_1'
      }
    ]);
  };

  return (
    <AppLayout
      contentType="cards"
      headerSelector="#h"
      navigation={<DFPHomePageSideNavigation />}
      toolsHide={true}
      notifications={<Flashbar items={flashbarItems} />}
      breadcrumbs={
        <DFPBreadcrumbs
          items={[
            { text: 'FinTech Ops', href: FinTechOpsHref.finTechOps },
            {
              text: 'Tickets',
              href: ''
            }
          ]}
        />
      }
      maxContentWidth={Number.MAX_VALUE}
      content={
        <div>
          <TicketContext.Provider
            value={{
              displayNotification,
              statusOfGraphTab,
              setStatusOfGraphTab,

              statusPieData,
              setStatusPieData,
              ageTicketData,
              setAgeTicketData,

              sevTicketsByDateData,
              setSevTicketsByDateData,
              ticketsByApplication,
              setTicketsByApplication,
              ticketsByDateStatus,
              setTicketsByDateStatus,
              ticketsByTeam,
              setTicketsByTeam,
              ticketsByTeamYDomain,
              setTicketsByTeamYDomain,
              ticketsByTeamXDomain,
              setTicketsByTeamXDomain,
              ticketsByApplicationYDomain,
              setTicketsByApplicationYDomain,
              ticketsByApplicationXDomain,
              setTicketsByApplicationXDomain,
              ticketsByDatesStatusDomain,
              setTicketsByDatesStatusDomain,
              ticketsByStatusYDomain,
              setTicketsByStatusYDomain,
              ticketsByResolution,
              setTicketsByResolution,
              ticketsByResolutionDomain,
              setTicketsByResolutionDomain,
              ticketsByTimeSpent,
              setTicketsByTimeSpent,
              ticketsByTimeSpentDomain,
              setTicketsByTimeSpentDomain,
              sevTicketsByDateDomain,
              setSevTicketsByDateDomain,
              ageTicketYDomain,
              setAgeTicketYDomain,
              ageTicketXDomain,
              setAgeTicketXDomain,

              statusOfDetailsTab,
              setStatusOfDetailsTab,
              aggregatedDataTableApplications,
              setAggregatedDataTableApplications,
              aggregatedDataTableTeams,
              setAggregatedDataTableTeams,
              ticketDetailsTable,
              setTicketDetailsTable,
              ticketResolvedDetailsTable,
              setTicketResolvedDetailsTable,
              filteredResolvedDetailsTable,
              setFilteredResolvedDetailsTable,
              openTicketContainer,
              setOpenTicketContainer,
              openTickets,
              setOpenTickets,
              openTicketsByTeam,
              setOpenTicketsByTeam,
              resolvedMonthsList,
              setResolvedMonthsList,
              filteredResolvedMonthList,
              setFilteredResolvedMonthsList,
              resolvedResolutionTypeList,
              setResolvedResolutionTypeList,
              filteredResolvedResolutionTypeList,
              setFilteredResolvedResolutionTypeList
            }}
          >
            <TicketsSelectionFilters />
            <Tabs
              tabs={[
                {
                  label: 'Overview',
                  id: 'tickets-OV',
                  content: <OverViewContent />
                },
                {
                  label: 'Details',
                  id: 'tickets-DT',
                  content: <TicketDetailsPage />
                }
              ]}
            />
          </TicketContext.Provider>
        </div>
      }
    />
  );
};
