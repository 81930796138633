import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContextProvider';
import { logger } from 'src/logger';
import { UserActionsEventTypes } from '../das-finsuite/das-finsuite-constants';

export const NavigationItems: SideNavigationProps.Item[] = [
  {
    type: 'link',
    text: 'All Apps',
    href: '/all-apps/#list'
  },
  {
    type: 'link',
    text: 'Announcements',
    href: '/announcements'
  },
  {
    type: 'link',
    text: 'Tutorials',
    href: '/tutorials'
  }
];

// TODO - Need to refactor
export const OperationalExcellenceSideNavItems: SideNavigationProps.Item[] = [
  {
    type: 'link',
    text: 'Tickets',
    href: '/fintech-ops/tickets'
  },
  {
    type: 'link',
    text: 'User Metrics',
    href: '/fintech-ops/user-metrics'
  },
  {
    type: 'link',
    text: 'SAS',
    href: '/fintech-ops/sas'
  },
  {
    type: 'link',
    text: 'Policy Engine',
    href: '/fintech-ops/policyEngine'
  },
  {
    type: 'link',
    text: 'Dogma',
    href: '/fintech-ops/dogma'
  },
  {
    type: 'link',
    text: 'Risk Remediator',
    href: '/fintech-ops/risk-remediator'
  },
  {
    type: 'link',
    text: 'Ops Mapping',
    href: '/fintech-ops/ops-mapping'
  },
  {
    type: 'link',
    text: 'Cost Metrics',
    href: '/fintech-ops/cost-metrics'
  }
];

const OperationalExcellenceSideNav: SideNavigationProps.Item = {
  type: 'link-group',
  text: 'FinTech Ops',
  href: '/fintech-ops',
  items: OperationalExcellenceSideNavItems
};

// TODO - Need to refactor
const OperationalExcellenceSideNavOnlyNonProd: SideNavigationProps.Item = {
  type: 'link-group',
  text: 'FinTech Ops',
  href: '/fintech-ops',
  items: OperationalExcellenceSideNavItems
};

export const OE_NAVIGATION_ITEMS = [
  {
    environment: 'dev',
    NavigationItems: OperationalExcellenceSideNavOnlyNonProd
  },
  {
    environment: 'alpha',
    NavigationItems: OperationalExcellenceSideNavOnlyNonProd
  },
  {
    environment: 'beta',
    NavigationItems: OperationalExcellenceSideNavOnlyNonProd
  },
  {
    environment: 'prod',
    NavigationItems: OperationalExcellenceSideNav
  }
];

export const DFPHomePageSideNavigation: React.FC = () => {
  const userAuthenticationDetails = useAuth();
  const Stage: any = 'beta';
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const [sideNavigationItems, setSideNavigationItems] = useState<SideNavigationProps.Item[]>(NavigationItems);
  const [activeRef, setActiveRef] = useState<string>('');

  useEffect(() => {
    setActiveRef(pathname + hash);
  }, [pathname, hash]);

  useEffect(() => {
    const OE_NAVIGATION = OE_NAVIGATION_ITEMS.find((c) => c.environment === Stage)?.NavigationItems;
    // TODO - Need to refactor
    setSideNavigationItems(userAuthenticationDetails.isOEReadOnly && OE_NAVIGATION ? NavigationItems.concat(OE_NAVIGATION) : NavigationItems);
  }, [userAuthenticationDetails]);

  return (
    <SideNavigation
      header={{
        href: '/',
        text: 'DaS FinSuite'
      }}
      items={sideNavigationItems}
      activeHref={activeRef}
      onFollow={(event) => {
        if (!event.detail.external) {
          logger.info(`DaS FinSuite -> User Action: FinSuite Home page side nav`, {
            event_type: UserActionsEventTypes.HOME_PAGE_SIDE_NAV_CLICK,
            value: event.detail.text
          });
          event.preventDefault();
          navigate(event.detail.href, {
            replace: true,
            state: { from: '/', redirectFromHomePage: true, fromAppName: '', fromAppId: '' }
          });
        }
      }}
    />
  );
};
