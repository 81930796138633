import { Box, Button, ColumnLayout, Container, FormField, Multiselect, MultiselectProps, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { useFinTechContext } from 'src/components/context/FintechContextProvider';
import LastRefreshedAt from 'src/components/generic-components/LastRefreshedAt';
import { TICKETS_FOR_OE_ERROR_URL } from 'src/constants/AppConstants';
import { logger } from 'src/logger';
import { getMultiSelectPlaceHolderValue, getUniqueFieldValues } from 'src/utilities/CommonUtilities';
import { dateTimeComparatorMMMYYYYFormat, getCurrentTime, getTimeDifference, sortedDateArrayMMMYYYYFormat } from 'src/utilities/DateTimeUtilities';
import { filtersLabel } from '../FinTechOpsCommonElements';
import { FinTechOpsMessages } from '../FinTechOpsConstants';
import {
  objectToOptions,
  optionsToObject,
  parseObjectToMultiSelectOptions_Sorted,
  returnFilterAgeSorted,
  returnPieSeries,
  returnSelectedOptionsAge
} from '../FinTechOpsDataProcessingFunctions';
import { useTicketContext } from './TicketsContext';
import {
  TicketResolutionDataProcess,
  TicketTimeSpentDataProcess,
  TicketStatusDataProcess,
  applyFiltersTickets,
  countByMonthBar,
  createMasterFilterDataTickets,
  createTicketGraphData,
  createTicketDetailedTabDataV2,
  getTicketByApplicationSeries,
  getTicketsByDayData,
  getResolvedMonthUniqueList
} from './TicketsDataProcessing';
import { TicketDetailsTabData, TicketFiltersResponse, TicketGraphsData, TicketsParsedDataItem } from './TicketsInterfaces';
import { getUniqueValues } from 'src/utilities/CommonHelpers';

export const TicketsSelectionFilters: React.FC = () => {
  const finOpsContext = useFinTechContext();
  const ticketContext = useTicketContext();

  const [masterFilters, setMasterFilters] = useState<TicketFiltersResponse>({
    ticket_managers: [],
    ticket_assignees: [],
    ticket_severity: [],
    ticket_status: [],
    ticket_age: [],
    ticket_application: [],
    ticket_resolver_group: [],
    team_name: []
  } as TicketFiltersResponse);

  // Selected list of Filters
  const [managers, setManagers] = useState<MultiselectProps.Options>([]);
  const [assignees, setAssignees] = useState<MultiselectProps.Options>([]);
  const [severity, setSeverity] = useState<MultiselectProps.Options>([]);
  const [ticketStatus, setTicketStatus] = useState<MultiselectProps.Options>([]);
  const [ticketAge, setTicketAge] = useState<MultiselectProps.Options>([]);
  const [application, setApplication] = useState<MultiselectProps.Options>([]);
  const [resolverGroup, setResolverGroup] = useState<MultiselectProps.Options>([]);
  const [team, setTeam] = useState<MultiselectProps.Options>([]);

  const [rawmanagers, setRawManagers] = useState<MultiselectProps.Options>([]);
  const [rawassignees, setRawAssignees] = useState<MultiselectProps.Options>([]);
  const [rawseverity, setRawSeverity] = useState<MultiselectProps.Options>([]);
  const [rawticketStatus, setRawTicketStatus] = useState<MultiselectProps.Options>([]);
  const [rawticketAge, setRawTicketAge] = useState<MultiselectProps.Options>([]);
  const [rawapplication, setRawApplication] = useState<MultiselectProps.Options>([]);
  const [rawresolverGroup, setRawResolverGroup] = useState<MultiselectProps.Options>([]);
  const [rawteam, setRawTeam] = useState<MultiselectProps.Options>([]);

  const [lastRefreshed, setLastRefreshed] = useState('');

  const [isRawFilter, setIsRawFilter] = useState(true);

  const [isResetFilter, setIsResetFilter] = useState(false);

  useEffect(() => {
    if (finOpsContext.finTechOpsTicketDataStatus === 'finished') {
      const ticketFilterValues = getTicketFilters(finOpsContext.finTechOpsTicketsData.data);
      const resolvedMonthUniqueList = getResolvedMonthUniqueList(finOpsContext.finTechOpsTicketsData.data);
      ticketContext.setResolvedMonthsList(resolvedMonthUniqueList?.sort((a, b) => dateTimeComparatorMMMYYYYFormat(a, b)));
      const resolvedResolutionTypeUniqueList = getUniqueFieldValues(finOpsContext.finTechOpsTicketsData.data, 'resolution_type');
      ticketContext.setResolvedResolutionTypeList(resolvedResolutionTypeUniqueList);
      calculateDataBasedOnNewFilterSelection(
        ticketFilterValues.assigneeStr,
        ticketFilterValues.managersStr,
        ticketFilterValues.resolverGroupStr,
        ticketFilterValues.ticketStatusStr,
        ticketFilterValues.severityStr,
        ticketFilterValues.ageStr,
        ticketFilterValues.applicationStr,
        ticketFilterValues.teamNameStr
      );
      setLastRefreshed(finOpsContext?.finTechOpsTicketsData?.metadata?.last_updated_at);
      setIsResetFilter(false);
    }

    if (finOpsContext.finTechOpsTicketDataStatus === 'loading') {
      ticketContext.setStatusOfDetailsTab('loading');
      ticketContext.setStatusOfGraphTab('loading');
    }

    if (finOpsContext.finTechOpsTicketDataStatus === 'error') {
      ticketContext.setStatusOfDetailsTab('error');
      ticketContext.setStatusOfGraphTab('error');
      finOpsContext.setDashboardFilterStatus(finOpsContext.finTechOpsTicketDataStatus);
      ticketContext.displayNotification(`error`, FinTechOpsMessages.APIError, TICKETS_FOR_OE_ERROR_URL);
    }
  }, [finOpsContext.finTechOpsTicketDataStatus, finOpsContext.dashboardFilterData, isResetFilter]);

  // get all unique values from Tickets API and creates All Master Filter Dropdowns
  const getTicketFilters = (ticketsData: TicketsParsedDataItem[]) => {
    const masterFilterData = createMasterFilterDataTickets(ticketsData);
    setMasterFilters(masterFilterData);
    const filterValues = selectFiltersWithAllOptionsByDefault(masterFilterData);
    return filterValues;
  };

  // By default All Filters to be selected with All options
  const selectFiltersWithAllOptionsByDefault = (_masterFilters: TicketFiltersResponse) => {
    try {
      //getting multiselect list of items
      const _managers = objectToOptions(_masterFilters.ticket_managers);
      const _assignees = objectToOptions(_masterFilters.ticket_assignees);
      const _severity = objectToOptions(_masterFilters.ticket_severity);
      const _ticketStatus = objectToOptions(_masterFilters.ticket_status);
      const _ticketAge = objectToOptions(_masterFilters.ticket_age);
      const _application = objectToOptions(_masterFilters.ticket_application);
      const _resolverGroup = objectToOptions(_masterFilters.ticket_resolver_group);
      const _teamName = objectToOptions(_masterFilters.team_name);

      //Updating state of selected item
      setManagers(_managers);
      setTicketAge(_ticketAge);
      setAssignees(_assignees);
      setApplication(_application);
      setResolverGroup(_resolverGroup);
      setSeverity(_severity);
      setTicketStatus(_ticketStatus);
      setTeam(_teamName);

      //one time setting values for placeholder value comparison with raw filter and currently applied filter
      if (isRawFilter === true) {
        setRawManagers(_managers);
        setRawTicketAge(_ticketAge);
        setRawAssignees(_assignees);
        setRawApplication(_application);
        setRawResolverGroup(_resolverGroup);
        setRawSeverity(_severity);
        setRawTicketStatus(_ticketStatus);
        setRawTeam(_teamName);
      }
      setIsRawFilter(false);

      const managersStr = optionsToObject(_managers);
      const assigneeStr = optionsToObject(_assignees);
      const ageStr = returnSelectedOptionsAge(_ticketAge);
      const applicationStr = optionsToObject(_application);
      const resolverGroupStr = optionsToObject(_resolverGroup);
      const severityStr = optionsToObject(_severity);
      const ticketStatusStr = optionsToObject(_ticketStatus);
      const teamNameStr = optionsToObject(_teamName);

      return { managersStr, assigneeStr, ageStr, applicationStr, resolverGroupStr, severityStr, ticketStatusStr, teamNameStr };
    } catch (error: any) {
      //logging any error
      ticketContext.setStatusOfDetailsTab('error');
      ticketContext.setStatusOfGraphTab('error');
      ticketContext.displayNotification(`error`, FinTechOpsMessages.APIErrorOverviewTab, TICKETS_FOR_OE_ERROR_URL);
      logger.error('Unable to update selected filter data', error);
      return {
        managersStr: [],
        assigneeStr: [],
        ageStr: [],
        applicationStr: [],
        resolverGroupStr: [],
        severityStr: [],
        ticketStatusStr: [],
        teamNameStr: []
      };
    }
  };

  // Determine if Filter should recaulate based on new filters
  const onTicketFilterBlur = () => {
    const ageStr = returnSelectedOptionsAge(ticketAge);
    const assigneeStr = optionsToObject(assignees);
    const applicationStr = optionsToObject(application);
    const managersStr = optionsToObject(managers);
    const resolverGroupStr = optionsToObject(resolverGroup);
    const severityStr = optionsToObject(severity);
    const ticketStatusStr = optionsToObject(ticketStatus);
    const teamNameStr = optionsToObject(team);
    calculateDataBasedOnNewFilterSelection(
      assigneeStr,
      managersStr,
      resolverGroupStr,
      ticketStatusStr,
      severityStr,
      ageStr,
      applicationStr,
      teamNameStr
    );
  };

  // Calculate data based on updated filters
  const calculateDataBasedOnNewFilterSelection = (
    _assignees: string[],
    _managers: string[],
    _resolverGroup: string[],
    _status: string[],
    _severity: string[],
    _age: string[],
    _application: string[],
    _teamName: string[]
  ) => {
    try {
      const start = getCurrentTime();

      const [filteredWithoutMonthData, filteredTicketsData]: [TicketsParsedDataItem[], TicketsParsedDataItem[]] = applyFiltersTickets(
        finOpsContext.finTechOpsTicketsData.data,
        {
          ticket_assignees: _assignees,
          ticket_managers: _managers,
          ticket_resolver_group: _resolverGroup,
          ticket_status: _status,
          ticket_severity: _severity,
          ticket_age: _age,
          ticket_application: _application,
          team_name: _teamName
        }
      );

      const oldestCreatedMonth = sortedDateArrayMMMYYYYFormat(getUniqueFieldValues(filteredTicketsData, 'create_date_UserTimeZone_formatted'))
        .slice(-1)
        .toString();

      getTicketFilters(filteredTicketsData);

      const calculatedGraphData: TicketGraphsData = createTicketGraphData(filteredTicketsData);

      const calculatedDetailsTabData: TicketDetailsTabData = createTicketDetailedTabDataV2(filteredTicketsData, filteredWithoutMonthData);

      const elapsed = getTimeDifference(start);
      logger.info(`Fetched calculated data for TicketsPage in ${elapsed} ms`);

      const severityGraph = countByMonthBar(calculatedGraphData.severityTickets);
      const applicationGraph = getTicketByApplicationSeries(calculatedGraphData.applicationTickets);
      const statusByDateGraph = TicketStatusDataProcess(calculatedGraphData.statusByMonthTickets, false);
      const incomingTicketsByMonth = TicketStatusDataProcess(calculatedGraphData.incomingTickets, true);
      const resolutionByDateGraph = TicketResolutionDataProcess(calculatedGraphData.resolutionTickets);
      const timeSpentByDateGraph = TicketTimeSpentDataProcess(calculatedGraphData.timeSpentTickets, oldestCreatedMonth);
      const ticketsByTeamData = TicketResolutionDataProcess(calculatedGraphData.ticketsByTeam);
      const dowAvgGraphData = getTicketsByDayData(calculatedGraphData.dayOfWeekTickets);
      ticketContext.setStatusPieData(returnPieSeries(calculatedGraphData.overAllStatusTickets));

      ticketContext.setTicketsByDateStatus(statusByDateGraph.dataSeries);
      ticketContext.setAgeTicketData(incomingTicketsByMonth.dataSeries);
      ticketContext.setTicketsByApplication(applicationGraph.dataSeries);
      ticketContext.setTicketsByResolution(resolutionByDateGraph.dataSeries);
      ticketContext.setTicketsByTimeSpent(timeSpentByDateGraph.dataSeries);
      ticketContext.setSevTicketsByDateData(severityGraph.dataSeries);
      ticketContext.setTicketsByTeam(ticketsByTeamData.dataSeries);

      //domains
      ticketContext.setSevTicketsByDateDomain(severityGraph.XDomain);
      ticketContext.setTicketsByResolutionDomain(resolutionByDateGraph.XDomain);
      ticketContext.setTicketsByTimeSpentDomain(timeSpentByDateGraph.XDomain);
      ticketContext.setTicketsByDatesStatusDomain(statusByDateGraph.xDomain);
      ticketContext.setTicketsByStatusYDomain([0, statusByDateGraph.yDomain]);
      ticketContext.setTicketsByApplicationYDomain([0, applicationGraph.YDomain]);
      ticketContext.setTicketsByApplicationXDomain(applicationGraph.XDomain);
      ticketContext.setAgeTicketYDomain([0, incomingTicketsByMonth.yDomain]);
      ticketContext.setAgeTicketXDomain(incomingTicketsByMonth.xDomain);
      ticketContext.setTicketsByTeamXDomain(ticketsByTeamData.XDomain);

      // Tickets - Details Tab
      ticketContext.setOpenTickets(calculatedDetailsTabData.ticketSummaryContainer.totalTickets);
      ticketContext.setOpenTicketsByTeam(calculatedDetailsTabData.ticketSummaryContainer.openTicketsByTeam);
      ticketContext.setOpenTicketContainer([calculatedDetailsTabData.ticketSummaryContainer.comparisonData]);

      //set table data
      ticketContext.setAggregatedDataTableApplications(calculatedDetailsTabData.aggregatedDataByApplications);
      ticketContext.setAggregatedDataTableTeams(calculatedDetailsTabData.aggregatedDataByTeams);
      ticketContext.setTicketDetailsTable(calculatedDetailsTabData.openTicketDetailsTableData);

      ticketContext.setTicketResolvedDetailsTable(calculatedDetailsTabData.resolvedTicketDetailsTableData);

      ticketContext.setFilteredResolvedDetailsTable(calculatedDetailsTabData.resolvedTicketDetailsTableData.rowData);

      const uniqueFilteredResolvedMonthList: string[] = [];

      calculatedDetailsTabData.resolvedTicketDetailsTableData.rowData.forEach((row) => {
        getUniqueValues(uniqueFilteredResolvedMonthList, row.resolved_date_UserTimeZone_formatted);
      });

      const uniqueFilteredResolvedResolutionTypeList = getUniqueFieldValues(
        calculatedDetailsTabData.resolvedTicketDetailsTableData.rowData,
        'resolution_type'
      );
      ticketContext.setFilteredResolvedMonthsList(uniqueFilteredResolvedMonthList);
      ticketContext.setFilteredResolvedResolutionTypeList(uniqueFilteredResolvedResolutionTypeList);

      ticketContext.setStatusOfDetailsTab('finished');
      ticketContext.setStatusOfGraphTab('finished');
    } catch (error: any) {
      ticketContext.setStatusOfDetailsTab('error');
      ticketContext.setStatusOfGraphTab('error');
      ticketContext.displayNotification(`error`, FinTechOpsMessages.dataProcessingError, TICKETS_FOR_OE_ERROR_URL);
      logger.error('Error in fetching calculated data for TicketsPage', error);
      logger.error('Unable to process tickets data', error);
    }
  };

  return (
    <div>
      <SpaceBetween size="l">
        <Box padding={{ top: 'xl' }}>
          <SpaceBetween size="s">
            <Container
              fitHeight
              header={
                <SpaceBetween size="m" direction="horizontal" alignItems="center">
                  <Box variant="h2">Tickets</Box>
                  <LastRefreshedAt lastRefreshedDateTime={lastRefreshed} />
                  <Button onClick={() => setIsResetFilter(true)}>Clear Filters</Button>
                </SpaceBetween>
              }
            >
              <Box>
                {/* <div className={styles.multiselectContainer}> */}
                {/* <Grid
                  gridDefinition={[
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } },
                    { colspan: { default: 3, xxs: 3, xs: 3, s: 3, m: 3, l: 3, xl: 3 } }
                  ]}
                > */}
                <SpaceBetween size="m" direction="vertical">
                  <ColumnLayout columns={4} minColumnWidth={10}>
                    <FormField label={filtersLabel('Team')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(team, 'Team', optionsToObject(rawteam))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.team_name, 'All Teams')}
                        selectedOptions={team}
                        onChange={({ detail }) => setTeam(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Teams"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>

                    <FormField label={filtersLabel('Managers')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(managers, 'Managers', optionsToObject(rawmanagers))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.ticket_managers, 'All Managers')}
                        selectedOptions={managers}
                        onChange={({ detail }) => setManagers(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Managers"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>

                    <FormField label={filtersLabel('Assignees')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(assignees, 'Assignees', optionsToObject(rawassignees))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.ticket_assignees, 'All Assignees')}
                        selectedOptions={assignees}
                        onChange={({ detail }) => setAssignees(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Assignees"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>

                    <FormField label={filtersLabel('Application')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(application, 'Application', optionsToObject(rawapplication))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.ticket_application, 'All Applications')}
                        selectedOptions={application}
                        onChange={({ detail }) => setApplication(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Applications"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>
                  </ColumnLayout>
                  <ColumnLayout columns={4}>
                    <FormField label={filtersLabel('Resolver Group')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(resolverGroup, 'Resolver Group', optionsToObject(rawresolverGroup))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.ticket_resolver_group, 'All Resolver Groups')}
                        selectedOptions={resolverGroup}
                        onChange={({ detail }) => setResolverGroup(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading ResolverGroups"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>

                    <FormField label={filtersLabel('Severity')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(severity, 'Severity', optionsToObject(rawseverity))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.ticket_severity, 'All Severity')}
                        selectedOptions={severity}
                        onChange={({ detail }) => setSeverity(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Severity"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>
                    <FormField label={filtersLabel('Status')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(ticketStatus, 'Status', optionsToObject(rawticketStatus))}
                        options={parseObjectToMultiSelectOptions_Sorted(masterFilters.ticket_status, 'All Status')}
                        selectedOptions={ticketStatus}
                        onChange={({ detail }) => setTicketStatus(detail.selectedOptions)}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Status"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>
                    <FormField label={filtersLabel('Month')}>
                      <Multiselect
                        placeholder={getMultiSelectPlaceHolderValue(ticketAge, 'Month', optionsToObject(rawticketAge))}
                        options={returnFilterAgeSorted(sortedDateArrayMMMYYYYFormat(masterFilters.ticket_age), 'All Months')}
                        selectedOptions={ticketAge}
                        onChange={({ detail }) => {
                          setTicketAge(detail.selectedOptions);
                        }}
                        onBlur={({ detail }) => onTicketFilterBlur()}
                        deselectAriaLabel={(e) => `Remove ${e.label}`}
                        selectedAriaLabel="Selected"
                        expandToViewport
                        hideTokens
                        virtualScroll
                        filteringType="auto"
                        loadingText="Loading Ages"
                        errorText="Unable to load data"
                        statusType={ticketContext.statusOfGraphTab}
                        disabled={ticketContext.statusOfGraphTab === 'loading' || ticketContext.statusOfDetailsTab === 'loading' ? true : false}
                      />
                    </FormField>
                  </ColumnLayout>
                </SpaceBetween>
              </Box>
            </Container>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </div>
  );
};
